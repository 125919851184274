export default function matterportEmbed() {
    const container = document.querySelector(".matterport-viewer")
    const root = document.querySelector(".matterport-viewer__root")
    const overlay = document.querySelector(".matterport-viewer__overlay")
    const trigger = document.querySelector<HTMLButtonElement>("#matterport-viewer-toggle")

    document.addEventListener('DOMContentLoaded', () => {
        // @ts-ignore
        if (!container || !root || !model || !sdk_key) return
        
        const viewer = document.createElement("matterport-viewer")
        // @ts-ignore
        viewer.setAttribute("src", `/matterport_bundle/showcase.html?m=${model}&applicationKey=${sdk_key}`)
    
        trigger.addEventListener("click", () => {
            root.appendChild(viewer)
            container.classList.add("active")
            setTimeout(() => {
                overlay.remove()
            }, 750)
        })
   
        const observer = new MutationObserver((_, obs) => {
            if (viewer) viewer.addEventListener('mpSdkPlaying', async (evt: any) => {})
            obs.disconnect()
        });
    
        observer.observe(document.body, { childList: true, subtree: true });
    });
}
